export default {
    zh:{
        unlock:{
            selTip:'请选择要解锁的记录',
            queryParam:{
                code:'编码',
                code_placeholder:'请输入编码模糊匹配查询...',
                name:'名称',
                name_placeholder:'请输入名称模糊匹配查询...',
            },
            columns:{
                F_CODE:'编码',
                F_NAME:'名称',
                F_CORP_NAME:'单位',
                F_DEPT_NAME:'部门',
                F_PERSON_NAME:'人员',
                F_LOCK_TIME:'锁定时间',
            },
        }
    },
    en:{
        unlock:{
            selTip:'Please select a record to unlock',
            queryParam:{
                code:'code',
                code_placeholder:'Please enter a code fuzzy matching query...',
                name:'name',
                name_placeholder:'Please enter a name fuzzy matching query...',
            },
            columns:{
                F_CODE:'code',
                F_NAME:'name',
                F_CORP_NAME:'organization',
                F_DEPT_NAME:'department',
                F_PERSON_NAME:'person',
                F_LOCK_TIME:'lock time',
            },
        }
    }
}